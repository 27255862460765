export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/about": [3],
		"/blog/accomplishments_of_openai": [4],
		"/blog/evolution": [5],
		"/blog/future_of_education": [6],
		"/blog/notable_ai": [7],
		"/blog/poincare": [8],
		"/blog/problems_with_modern_vc_interest": [9],
		"/blog/why_calculus": [10],
		"/blog/why_learn": [11],
		"/compare": [12],
		"/demo": [13],
		"/demo/instructors/old": [14],
		"/demo/instructors/old/old2": [15],
		"/faqs": [16],
		"/ifaqs": [17],
		"/legal/terms_of_service": [18],
		"/mission": [19],
		"/mission/gb": [20],
		"/mission/spooky": [21],
		"/play": [22],
		"/privacy_policy": [23],
		"/scheduling-thanks": [24],
		"/scroll_demo": [25]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';